<template>
  <section id="animation">
    <b-container>
      <b-row>
        <b-col md="12">
          <div class="text-center">
            <div id="player-overlay">
              <video loop autoplay muted playsinline>
                <source src="../assets/animation.mp4" />
              </video>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="btn-cta">
        <b-col md="12">
          <div class="text-center">
            <a href="#contact" v-smooth-scroll
              ><b-button class="button-custom">Contact Us</b-button></a
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<style lang="scss" scoped>
#animation {
  padding-top: 150px;
}
video {
  width: 100%;
  max-height: 100%;
}
.btn-cta {
  margin-top: 50px;
  margin-bottom: 100px;
}
.button-custom {
  background: #f6f6f6;
  color: red;
  font-weight: 600;
  border: 1px solid #f6f6f6;
}
</style>

<script>
export default {
  name: "Animation1",
};
</script>
