<template>
  <section id="contact">
    <b-container>
      <b-row class="mb-5">
        <b-col md="12">
          <div class="text-center"></div>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col offset-md="2" class="text-center contact-text" md="8">
          <h1 class="mb-4">Your contact</h1>
          <img
            class="img-fluid mb-4 contact-img"
            src="../assets/contact-person.jpg"
            alt="contact-person-img"
          />
          <ul class="list-unstyled contact-info">
            <li>
              <b>Valérie André</b>
            </li>
            <li class="mb-3"><b>Head of Media Strategy</b></li>
            <li>
              <p>
                Valérie is a federally certified communications planner and has
                been working in the media industry for over ten years. As Head
                of Media Strategy, she develops holistic and innovative
                campaigns with the team. In doing so, the classical media
                experts are fully integrated into our digital teams, which
                enables us to have a common strategic understanding of all
                experts and an integrated planning of digital and classical
                measures.
              </p>
            </li>
          </ul>
        </b-col>
      </b-row>

      <b-row>
        <b-col offset-md="2" md="8">
          <p>
            <b>We are looking forward to your contact.</b>
          </p>
          <div class="form-group">
            <form action="https://formspree.io/f/mbjwperd" method="POST">
              <input
                required
                type="text"
                class="form-control"
                name="name"
                placeholder="Name*"
              />
              <input
                required
                type="text"
                class="form-control mt-3"
                name="company"
                placeholder="Company*"
              />
              <input
                required
                type="email"
                class="form-control mt-3"
                name="company"
                placeholder="Email*"
              />
              <textarea
                name="message"
                placeholder="Message*"
                required
                rows="4"
                aria-required="true"
                class="mt-3 form-control"
              ></textarea>
              <div class="text-center">
                <b-button type="submit" class="mt-3 button-custom px-4"
                  >Send</b-button
                >
              </div>
            </form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<style lang="scss" scoped>
#contact {
  padding-top: 80px;
  margin-top: -40px;
  padding-bottom: 50px;
}
.contact-info {
  li {
    font-size: 15px;
    font-weight: 300;
    b {
      font-weight: 600;
    }
  }
}
input,
textarea {
  border-radius: 7px;
  border: 0px;
  padding: 25px;
}
.contact-text {
  margin-top: -73px;
}
.contact-img {
  border-radius: 50%;
  width: 146px;
}
.button-custom {
  background: #f6f6f6;
  color: red;
  font-weight: 600;
  border: 1px solid #f6f6f6;
}
</style>

<script>
export default {
  name: "Contact",
};
</script>
