<template>
  <section id="content">
    <b-container>
      <!-- Title -->
      <b-row>
        <b-col md="12">
          <div class="text-center">
            <h1>360° with Webrepublic</h1>
          </div>
        </b-col>
      </b-row>

      <!-- Article text-->
      <b-row class="article-text">
        <b-col offset-md="1" md="10">
          <h1 class="text-center font-weight-light">
            We extend strategies that work digitally into classic media. In
            doing so, we use digital potentials and reduce complexity to extend
            them to all media. In this way, we link both worlds - from the first
            TV spot to the digital customer journey on search, social media, and
            the website.
          </h1>
        </b-col>
      </b-row>

      <!-- Grids -->
      <b-row>
        <b-col class="mb-5" offset-md="1" md="10">
          <div class="text-center">
            <h4>Integrated concepts</h4>
            <p>
              Outstanding campaigns are created at the interface between
              strategy, technology, media, and creation. To create concepts that
              really mesh and consider all interdependencies, we work in
              co-creation mode: specialists from all areas work iteratively on a
              shared understanding of goals, measures, and potential. This is
              how modern strategies with reduced operational complexity are
              created.
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-5" offset-md="1" md="10">
          <div class="text-center">
            <h4>Digital data for better planning</h4>
            <p>
              We measure the impact of classic media on digital KPIs: How does
              the search volume of brands and products change during a billboard
              flight? How do newly acquired users behave on the website because
              of a TV campaign? Our 360° media dashboards provide real-time
              information on overall performance. We use data from the digital
              world to make media plans smarter: Where is user interest highest?
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-5" offset-md="1" md="10">
          <div class="text-center">
            <h4>Programmatic booking</h4>
            <p>
              Real-time bidding is on the rise. What has long been established
              digitally is also spreading to the physical world: digital outdoor
              spaces can be targeted in real time; TV and radio are becoming
              increasingly programmatic. This allows us to reach the right
              customers at the right time across all media, respond flexibly to
              market developments, and move toward agile media plans.
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-5" offset-md="1" md="10">
          <div class="text-center">
            <h4>Digital and classic creation</h4>
            <p>
              We analyze digital performance data to improve campaign strategy:
              Which moving image ads show the biggest consideration uplift on
              YouTube? Which messages convert best on social media? We use this
              data to optimize subjects for posters, digital screens, and TV.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<style lang="scss" scoped>
#content {
  padding-top: 0px;
}
.article-text {
  margin-bottom: 50px;
}
h1 {
  font-size: 1.8rem !important;
  font-weight: 600;
}
h4 {
  font-weight: 600;
}
</style>

<script>
export default {
  name: "Content",
};
</script>
