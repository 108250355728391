<template>
  <div>
    <!-- Collapsed Hamburger/Logo -->
    <b-navbar class="fixed-top menu" toggleable="lg">
      <ul class="list-inline">
        <li class="list-inline-item">
          <a href="#animation" v-smooth-scroll
            ><img
              class="img-fluid logo-mob mr-5"
              src="../assets/nav-logo.png"
              alt=""
          /></a>
        </li>
        <li class="list-inline-item">
          <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
        </li>
      </ul>

      <!-- Menu Links/logo -->
      <b-container>
        <b-col class="d-none d-sm-block" md="2">
          <a href="#animation" v-smooth-scroll
            ><img
              class="img-fluid logo"
              src="../assets/nav-logo.png"
              alt=""/></a
        ></b-col>
        <b-col class="menu-collapse-items" md="8">
          <b-collapse id="nav-text-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item>
                <a href="#cases" v-smooth-scroll>Our Cases</a>
              </b-nav-item>
              <b-nav-item>
                <a href="#contact" v-smooth-scroll>Contact Us</a>
              </b-nav-item>
              <li class="mt-2 mr-2">
                <a :href="changeLanguage('de')">DE</a>
              </li>
              <li class="mt-2">
                <a class="active" :href="changeLanguage('en')">EN</a>
              </li>
            </b-navbar-nav>
          </b-collapse>
        </b-col>
      </b-container>
    </b-navbar>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  background: #e6273e;
  ul {
    padding: 12px 0px 0px 0px;
    li {
      margin-right: 4px;
      a {
        color: white;
        text-decoration: none;
        white-space: nowrap;
        font-weight: 300;
        &:hover {
          color: white;
          font-weight: 600;
          transition: 0.3s;
        }
      }
    }
  }
  .active {
    font-weight: 600;
  }
}
@media screen and (max-width: 550px) {
  .menu {
    background: #d72537;
  }
}
.navbar-toggler {
  position: absolute;
  right: 20px;
  top: 10px;
  border: 0px;
}

.navbar-collapse {
  margin-top: -7px;
}

.logo {
  display: none;
  max-width: 35px;
  margin-top: 2px;
}
.logo-mob {
  display: none;
  max-width: 35px;
}
@media screen and (min-width: 992px) {
  .logo {
    display: block;
  }
}
@media screen and (max-width: 991px) {
  .logo-mob {
    display: block;
    width: 60px;
    margin-top: -15px;
  }
}
</style>

<script>
export default {
  name: "TheMenu",
  mounted() {
    this.activeNavLink();
  },
  methods: {
    activeNavLink() {
      const sections = document.querySelectorAll("section[id]");
      window.addEventListener("scroll", navHighlighter);
      function navHighlighter() {
        let scrollY = window.pageYOffset;
        sections.forEach((current) => {
          const sectionHeight = current.offsetHeight;
          const sectionTop =
            current.getBoundingClientRect().top + window.pageYOffset - 50;
          var sectionId = current.getAttribute("id");
          var menuLinks = document.querySelector(
            ".menu ul li a[href*=" + sectionId + "]"
          );
          if (menuLinks) {
            if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
              menuLinks.classList.add("active");
            } else {
              menuLinks.classList.remove("active");
            }
          }
        });
      }
    },
    changeLanguage(lang) {
      if (lang === "en") {
        return process.env.VUE_APP_LANG_EN;
      }
      if (lang === "de") {
        return process.env.VUE_APP_LANG_DE;
      }
    },
  },
};
</script>
